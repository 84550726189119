// tạo điều hướng cho cá .vue ở view
import { createRouter, createWebHistory } from "vue-router";
// tạo

// import ForgotPassword from '../src/auth/forgot_password.vue';
const routes = [
  {
    path: "/",
    redirect: { name: "Register" }, // mặc định điều hướng login
  },
  // {
  //   path: "/forgot-password",
  //   name: "ForgotPassword",
  //   meta{git
  //     layout: "forgot-password",
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ " ../src/auth/forgot_password.vue"),
  // },
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/RegisterView.vue"),
  },
  {
    path: "/management",
    name: "Management",
    component: () => import("../views/ManagementView.vue"),
  },
  {
    path: "/edit/:id",
    name: "EditForm",
    component: () => import("../views/EditForm.vue"),
    props: true,
  },

  {
    path: "/auth/login",
    name: "Login",
    meta: {
      layout: "login-layout",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../auth/userLogin.vue"),
  },
  {
    path: "/forgot-password",
    name: "Forgot",
    meta: {
      layout: "forgot-password",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../auth/forgotPassword.vue"),
  },
  {
    path: "/auth/register",
    name: "register",
    meta: {
      layout: "register-layout",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../auth/userRegister.vue"),
  },
  {
    path: "/auth/create",
    name: "CreateAccount",
    meta: {
      layout: "create-layout",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../auth/createAccount.vue"),
  },
  {
    path: "/auth/otp",
    name: "SendOtp",
    meta: {
      layout: "otp-layout",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../auth/sendOtp.vue"),
  },
  {
    path: "/requirements",
    name: "Requirements",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/RequirementsView.vue"),
  },
  {
    path: "/resume-and-guides",
    name: "Resume",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ResumeView.vue"),
  },
  {
    path: "/explore",
    name: "Explore",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ExploreView.vue"),
  },
  // {
  //   path: "/:pathMatch(.*)*",
  //   name: "NotFound",
  //   component: () => import("../views/NotFound.vue"),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL), // lịch sử điều hướng dựa rên trình duyệt
  routes,
});

export default router; // xuất router này ra ngoài để sử dụng cho các export default
