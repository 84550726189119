<template>
  <HeaderLoginedComponent v-if="isLoggedIn" />
  <HeaderComponent v-else />
  <!-- Nội dung khác của ứng dụng -->
  <slot />
  <FooterComponent />
</template>

<script>
import Cookies from "js-cookie";
import HeaderLoginedComponent from "@/components/HeaderLoginedComponent.vue";
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
export default {
  components: {
    HeaderComponent,
    FooterComponent,
    HeaderLoginedComponent,
  },
  data() {
    return {
      isLoggedIn: false,
    };
  },
  mounted() {
    this.checkLoginStatus();
  },
  methods: {
    checkLoginStatus() {
      const token = Cookies.get("access_token");
      this.isLoggedIn = !!token; 
    },
  },
};
</script>
