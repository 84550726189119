<template>
    <slot />
  </template>
  
  <script>
  export default {
    components: {
    },
    setup() {},
  };
  </script>
  