import { createApp } from "vue";
import App from "./App.vue";

import router from "./router";
import "@/assets/styles/tailwind.css";
import "@/assets/styles/global.css";
import { registerGlobalComponents } from "./utils/import"; 

const app = createApp(App);
app.use(router);

registerGlobalComponents(app); //đăng ký các component toàn cục



app.mount("#app");
