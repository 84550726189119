import { defineAsyncComponent } from "vue";

export function registerGlobalComponents(app) {
  app.component(
    "default-layout", //tạo
    defineAsyncComponent(() => import("@/layout/default-layout")) 
  );
}


// import cái componet có tên "default-layout " để có thể sử dụng mọi nơi trong dự án

// đăng ký một component toàn cục (global component)