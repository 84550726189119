<template>
  <!-- <div style="background-color: red;"> Tôi là ông mặt trời {{ layout }}</div> -->
  <!-- sử dụng tới default-layout.vue , thành phần động-->
  <component :is="layout">
    <router-view/>
  </component>
</template>

<script>
import DefaultLayout from "@/layout/default-layout.vue";
import LoginLayout from "@/layout/login-layout.vue";
import ForgotPassword from "@/layout/forgot-password.vue";
import RegisterLayout from "@/layout/register-layout.vue";
import CrateAccount from "@/layout/create-layout.vue";
import SendOtp from "@/layout/otp-layout.vue";




export default {
  components: {LoginLayout, DefaultLayout, ForgotPassword, RegisterLayout, CrateAccount, SendOtp},
  middleware: ["authenticated"],
  data() {
    return {
      layout: "default-layout"
    };
  },
  watch: {
    "$route": {
      handler(to) {
        if (to.meta.layout) {
          this.layout = to.meta.layout;
        } else {
          this.layout = "default-layout";
        }
      },
      deep: true
    }
  },

};
</script>
