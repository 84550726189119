export const SCHEDULE_LIST = [
  {
    heading: "Chương trình Cử nhân 2+2 CNTT",
    subtitle: "Liên kết đào tạo nhận bằng ĐH La Trobe",
    date: "01.01",
    year: "2024",
  },
  {
    heading: "Chương trình Cử nhân An toàn thông tin",
    subtitle: "Liên kết đào tạo nhận bằng ĐH Bellevue",
    date: "01.01",
    year: "2024",
  },
  {
    heading: "Chương trình Cử nhân 2+2 Đa phương tiện",
    subtitle: "Liên kết đào tạo nhận bằng ĐH Middlesex",
    date: "07.01",
    year: "2024",
  },
  {
    heading: "Chương trình Cử nhân 2+2 Kỹ thuật Điện tử",
    subtitle: "Liên kết đào tạo nhận bằng ĐH Huddersfield",
    date: "12.01",
    year: "2024",
  },
];

export const NAV_ITEMS = [
  {
    name: "Register",
    text: "Đăng ký xét tuyển",
    isActive: true,
  },
  // {
  //   name: "Managemen",
  //   text: "Quản lí hồ sơ",
  //   isActive: true,
  // },
  {
    name: "Requirements",
    text: "Điều kiện xét tuyển",
    isActive: false,
  },
  {
    name: "Resume",
    text: "Hồ sơ và cách đăng ký",
    isActive: false,
  },
  {
    name: "Explore",
    text: "Khám phá CIE",
    isActive: false,
  },
];
export const SEX_LIST = ["Nam", "Nữ", "Khác"];

export const YEARS_LIST = [
  { name: "2019" },
  { name: "2020" },
  { name: "2021" },
  { name: "2022" },
  { name: "2023" },
  { name: "2024" },
  { name: "2025" },
  { name: "2026" },
  { name: "2027" },
  { name: "2028" },
  { name: "2029" },
  { name: "2030" },
  { name: "2031" },
  { name: "2032" },
  { name: "2033" },
  { name: "2034" },
  { name: "2035" },
];

export const SUBJECT_LIST = ["A00(Toán, Vật Lý, Hóa Học)", "A01(Toán, Vật Lý, Tiếng Anh)", "D01(Toán, Văn,Tiếng Anh)"];

export const PROGRAM_LIST = [
  {
    title:
      "CHƯƠNG TRÌNH CỬ NHÂN CÔNG NGHỆ THÔNG TIN 2+2 QUỐC TẾ VỚI ĐẠI HỌC LA TROBE, AUSTRALIA",
    img: require("@/assets/images/png/latrobe-img.png"),
    link: "https://cie.ptit.edu.vn/vi/prog/chuong-trinh-du-hoc-chuyen-tiep-sang-hoc-tap-tai-dai-hoc-la-trobe-uc-nam-2023/",
  },
  {
    title:
      "CHƯƠNG TRÌNH CỬ NHÂN AN TOÀN THÔNG TIN 2+2 HOẶC 3+1 VỚI ĐẠI HỌC CANBERRA, AUSTRALIA",
    img: require("@/assets/images/png/canberra 1.png"),
    link: "https://cie.ptit.edu.vn/vi/prog/chuong-trinh-cu-nhan-quoc-te-nganh-da-phuong-tien-31-hoac-22-voi-dai-hoc-canberra-australia-nam-2023/",
  },
  {
    title:
      "CHƯƠNG TRÌNH CỬ NHÂN ĐA PHƯƠNG TIỆN 3+1 VỚI ĐẠI HỌC MIDDLESEX, VƯƠNG QUỐC ANH",
    img: require("@/assets/images/png/middlesex-img.png"),
    link: "https://cie.ptit.edu.vn/vi/prog/chuong-trinh-du-hoc-chuyen-tiep-sang-hoc-tap-tai-dai-hoc-middlesex-vuong-quoc-anh/",
  },
  {
    title:
      "CHƯƠNG TRÌNH CỬ NHÂN ĐA PHƯƠNG TIỆN 2+2 VỚI ĐẠI HỌC HUDDERSFIELD, VƯƠNG QUỐC ANH",
    img: require("@/assets/images/png/huddlesfield-img.png"),
    link: "https://cie.ptit.edu.vn/vi/prog/chuong-trinh-du-hoc-chuyen-tiep-22-hoac-31-sang-hoc-tap-tai-dai-hoc-huddersfield-vuong-quoc-anh-nam-2023/",
  },
];

export const CITIES = [
  { id: 1, name: "An Giang" },
  { id: 2, name: "Bà Rịa - Vũng Tàu" },
  { id: 3, name: "Bạc Liêu" },
  { id: 4, name: "Bắc Kạn" },
  { id: 5, name: "Bắc Giang" },
  { id: 6, name: "Bắc Ninh" },
  { id: 7, name: "Bến Tre" },
  { id: 8, name: "Bình Dương" },
  { id: 9, name: "Bình Định" },
  { id: 10, name: "Bình Phước" },
  { id: 11, name: "Bình Thuận" },
  { id: 12, name: "Cà Mau" },
  { id: 13, name: "Cần Thơ" },
  { id: 14, name: "Cao Bằng" },
  { id: 15, name: "Đà Nẵng" },
  { id: 16, name: "Đắk Lắk" },
  { id: 17, name: "Đắk Nông" },
  { id: 18, name: "Điện Biên" },
  { id: 19, name: "Đồng Nai" },
  { id: 20, name: "Đồng Tháp" },
  { id: 21, name: "Hà Giang" },
  { id: 22, name: "Hà Nam" },
  { id: 23, name: "Hà Nội" },
  { id: 24, name: "Hà Tây" },
  { id: 25, name: "Hải Dương" },
  { id: 26, name: "Hải Phòng" },
  { id: 27, name: "Hậu Giang" },
  { id: 28, name: "Hòa Bình" },
  { id: 29, name: "Hưng Yên" },
  { id: 30, name: "Khánh Hòa" },
  { id: 31, name: "Kiên Giang" },
  { id: 32, name: "Kon Tum" },
  { id: 33, name: "Lai Châu" },
  { id: 34, name: "Lâm Đồng" },
  { id: 35, name: "Lạng Sơn" },
  { id: 36, name: "Lào Cai" },
  { id: 37, name: "Long An" },
  { id: 38, name: "Nam Định" },
  { id: 39, name: "Nghệ An" },
  { id: 40, name: "Ninh Bình" },
  { id: 41, name: "Ninh Thuận" },
  { id: 42, name: "Phú Thọ" },
  { id: 43, name: "Phú Yên" },
  { id: 44, name: "Quảng Bình" },
  { id: 45, name: "Quảng Nam" },
  { id: 46, name: "Quảng Ngãi" },
  { id: 47, name: "Quảng Ninh" },
  { id: 48, name: "Quảng Trị" },
  { id: 49, name: "Sóc Trăng" },
  { id: 50, name: "Sơn La" },
  { id: 51, name: "Tây Ninh" },
  { id: 52, name: "Thái Bình" },
  { id: 53, name: "Thái Nguyên" },
  { id: 54, name: "Thanh Hóa" },
  { id: 55, name: "Thừa Thiên Huế" },
  { id: 56, name: "Tiền Giang" },
  { id: 57, name: "Tuyên Quang" },
  { id: 58, name: "Vĩnh Long" },
  { id: 59, name: "Vĩnh Phúc" },
  { id: 60, name: "Yên Bái" },
  { id: 61, name: "Hồ Chí Minh" },
  { id: 62, name: "Hưng Yên" },
];
